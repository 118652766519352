import { gql } from '@apollo/client';

export const GET_DOCUMENT = gql`
    query Content($manualCode: String!, $fileName: String!, $isPreview: Boolean, $isHtml: Boolean, $versionNumber: String, $includeRevDetails: Boolean, $includeComplianceInformation: Boolean, $employeeId: String) {
        contentStore {
            viewFile(manualcode: $manualCode, filename: $fileName, isPreview: $isPreview, isHtml: $isHtml, versionNumber: $versionNumber, includeRevDetails: $includeRevDetails, includeComplianceInformation: $includeComplianceInformation, employeeId: $employeeId) {
                fileName
                fileStreamBase64
            }
        }
    }
`;

export const GET_DOCUMENT_V2 = gql`
    query Content($manualCode: String!, $fileName: String!, $isPreview: Boolean, $isHtml: Boolean, $versionNumber: String, $includeRevDetails: Boolean, $includeComplianceInformation: Boolean, $employeeId: String) {
        contentStore {
            viewFileV2(manualcode: $manualCode, filename: $fileName, isPreview: $isPreview, isHtml: $isHtml, versionNumber: $versionNumber, includeRevDetails: $includeRevDetails, includeComplianceInformation: $includeComplianceInformation, employeeId: $employeeId) {
                fileName
                fileStreamBase64
                contentId
                revDetails
                isPreview
            }
        }
    }
`;